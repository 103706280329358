window.NEO={}

window.NEO.typeCodeByBuy="buy"
window.NEO.typeCodeByAlbum="album_add_purchase"
window.NEO.typeCodeBySelect="select"


#***** アプリURLとAPIURLの定義 *****
appUrlBase = ".."
#apiUrlBase = "https://neo-dev3.rashin-net.com"
apiUrlBase = ""
window.NEO.debug=false
window.NEO.url =
  outsite_page_album_add_purchase_top:"#{appUrlBase}/album/"

  page_help:"#{appUrlBase}/help/indexbuy2.html"
  page_help_signup:"#{appUrlBase}/help/qa/"
  page_help_select:"#{appUrlBase}/help/qa/select/"
  page_help_buy:"#{appUrlBase}/help/qa/buy/"
  page_help_album:"#{appUrlBase}/help/indexbuy2.html"
  page_transaction:"#{appUrlBase}/transaction/index.html"
  page_help_privacy:"#{appUrlBase}/help/privacy/privacy.html"

  page_top:"#{appUrlBase}/signup3/"
  page_signup:"#{appUrlBase}/signup3/signup.html"
  page_confirm:"#{appUrlBase}/signup3/confirm.html"
  page_issue:"#{appUrlBase}/signup3/issue.html"
  page_ps_signup:"#{appUrlBase}/signup3/ps_signup.html"
  page_ps_confirm:"#{appUrlBase}/signup3/ps_confirm.html"

  page_login:"#{appUrlBase}/signup3/login.html"

  page_select_select:"#{appUrlBase}/select3/select.html"
  page_buy_select:"#{appUrlBase}/buy3/select.html"
  page_album_select:"#{appUrlBase}/album3/select.html"

  page_select_name_confirm:"#{appUrlBase}/select3/name_confirm.html"
  page_select_select_list:"#{appUrlBase}/select3/select_list.html"
  page_select_outro:"#{appUrlBase}/select3/outro.html"
  page_select_logout:"#{appUrlBase}/select3/logout.html"

  page_buy_decision:"#{appUrlBase}/buy3/decision.html"
  page_buy_payment:"#{appUrlBase}/buy3/payment.html"
  page_buy_customer:"#{appUrlBase}/buy3/customer.html"
  page_buy_destination:"#{appUrlBase}/buy3/destination.html"
  page_buy_last_confirm:"#{appUrlBase}/buy3/last_confirm.html"
  page_buy_smbc:"#{appUrlBase}/buy3/smbc.html"
  page_buy_smbc_error:"#{appUrlBase}/buy3/smbcerror.html"
  page_buy_outro:"#{appUrlBase}/buy3/outro.html"
  page_buy_logout:"#{appUrlBase}/buy3/logout.html"

  page_album_decision:"#{appUrlBase}/album3/decision.html"
  page_album_payment:"#{appUrlBase}/album3/payment.html"
  page_album_customer:"#{appUrlBase}/album3/customer.html"
  page_album_destination:"#{appUrlBase}/album3/destination.html"
  page_album_last_confirm:"#{appUrlBase}/album3/last_confirm.html"
  page_album_smbc:"#{appUrlBase}/album3/smbc.html"

  page_album_logout:"#{appUrlBase}/album3/logout.html"

  page_logout:"#{appUrlBase}/buy2/logout.html"
  page_select:"#{appUrlBase}/buy2/select.html"
  page_decision:"#{appUrlBase}/buy2/decision.html"
  page_payment:"#{appUrlBase}/buy2/payment.html"
  page_customer:"#{appUrlBase}/buy2/customer.html"
  page_destination:"#{appUrlBase}/buy2/destination.html"
  page_last_confirm:"#{appUrlBase}/buy2/last_confirm.html"
  page_smbc:"#{appUrlBase}/buy2/smbc.html"

  page_photostand_item_select: "#{appUrlBase}/buy3/photostand_item_select.html"
  page_photostand_photo_select: "#{appUrlBase}/buy3/photostand_photo_select.html"
  page_photostand_add_select: "#{appUrlBase}/buy3/photostand_add_select.html"

  ajax_albums_prefecture:"#{apiUrlBase}/srv/api/v2/albums/prefecture"
  ajax_albums_school_spec:"#{apiUrlBase}/srv/api/v2/albums/school_spec"
  ajax_albums_prefecture_city:"#{apiUrlBase}/srv/api/v2/albums/prefecture/"
  ajax_albums_auth:"#{apiUrlBase}/srv/api/v2/albums/auth"

  ajax_users_sign_up:"#{apiUrlBase}/srv/api/v2/users/sign_up"
  ajax_users_ps_sign_up:"#{apiUrlBase}/srv/api/v2/users/sign_up_psuser"

  ajax_sign_up_psuser:"#{apiUrlBase}/srv/api/v2/users/sign_up_psuser"
  ajax_oauth_token:"#{apiUrlBase}/srv/api/v2/oauth/token"

  ajax_albums_info:"#{apiUrlBase}/srv/api/v2/albums/info"

  ajax_orders_info:"#{apiUrlBase}/srv/api/v2/orders/info"
  ajax_orders_mark:"#{apiUrlBase}/srv/api/v2/orders/mark"

  ajax_products_list:"#{apiUrlBase}/srv/api/v2/products/list"
  ajax_variant_types_list:"#{apiUrlBase}/srv/api/v2/variant_types/list"
  ajax_assets_info:"#{apiUrlBase}/srv/api/v2/assets/info"

  ajax_cart_add:"#{apiUrlBase}/srv/api/v2/cart/add"
  ajax_cart_del:"#{apiUrlBase}/srv/api/v2/cart/del"
  ajax_cart_update:"#{apiUrlBase}/srv/api/v2/cart/update"

  ajax_payment_info:"#{apiUrlBase}/srv/api/v2/orders/payment_info"
  ajax_payment_update:"#{apiUrlBase}/srv/api/v2/orders/payment_update"

  ajax_payment_info:"#{apiUrlBase}/srv/api/v2/orders/payment_info"
  ajax_payment_update:"#{apiUrlBase}/srv/api/v2/orders/payment_update"

  ajax_bill_address_info:"#{apiUrlBase}/srv/api/v2/orders/bill_address_info"
  ajax_bill_address_update:"#{apiUrlBase}/srv/api/v2/orders/bill_address_update"

  ajax_ship_address_info:"#{apiUrlBase}/srv/api/v2/orders/ship_address_info"
  ajax_ship_address_update:"#{apiUrlBase}/srv/api/v2/orders/ship_address_update"

  ajax_orders_smbc_info:"#{apiUrlBase}/srv/api/v2/orders/smbc_info"
  ajax_orders_smbc_gmo_card_payment:"#{apiUrlBase}/srv/api/v2/orders/smbc_gmo_card_payment"
  ajax_users_sign_out:"#{apiUrlBase}/srv/api/v2/users/sign_out"

  ajax_access_token_with_hash:"#{apiUrlBase}/srv/api/v2/oauth/token_with_hash"

  ajax_namecheck_list:"#{apiUrlBase}/srv/api/v2/products/namecheck_list"
  ajax_namecheck_set:"#{apiUrlBase}/srv/api/v2/products/namecheck_set"

  ajax_cart_stand_cartset:"#{apiUrlBase}/srv/api/v2/cart/stand_cartset"
  ajax_cart_stand_quantity:"#{apiUrlBase}/srv/api/v2/cart/stand_quantity"

# ===============================================
# 共通ライブラリ
# ================================================
#空白と空の判定
window.NEO.is_blank = (s) ->!s? || s.trim?() is ''

#金額フォーマット
window.NEO.money_format = (num) -> String(num).replace /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'

#数字の0埋め
window.NEO.zeroPadding = (number, length)-> (Array(length).join('0') + parseInt(number)).slice(-length)

#改行置換
window.String.prototype.nl2br = -> this.replace(/(\r\n|\r|\n)/g, '<br>')
window.String.prototype.br2nl = -> this.replace(/<br>/g, '\n')

#セッションストレージの利用
window.NEO.s_session=$.sessionStorage

#***** knockout系 *****
#DOM要素に対してスクロール合わせのカスタムバインディング
ko.bindingHandlers.scrollTo = update: (element, valueAccessor, allBindings) ->
  _value = valueAccessor()
  _valueUnwrapped = ko.unwrap(_value)
  if _valueUnwrapped
    element.scrollIntoView()
    window.scrollBy(0, -($(element).outerHeight() * 1.5))
  @#

#***** $.Deferredに対応したAjax通信関数 *****
# APIをGETで取得する関数
window.NEO.get_api = (req_url) ->
  dfd = $.Deferred()
  $.ajax
    type: 'GET',
    url : req_url,
    dataType: 'json'
  .done (res) ->
    dfd.resolve(res)
  .fail (res) ->
    dfd.reject(res)
  return dfd.promise()

# APIにPOSTで送信する関数
window.NEO.post_api = (req_url, post_data) ->
  dfd = $.Deferred()
  $.ajax
    type: 'POST',
    url : req_url,
    dataType: 'json',
    data: post_data
  .done (res) ->
    dfd.resolve(res)
  .fail (res) ->
    dfd.reject(res)
  return dfd.promise()

$ ->
  #***** iOSでのモーダル表示時の背画面スクロールへの対策処理 *****
  window.NEO.current_scrollY = 0

  #モーダルをセンターに固定する
  window.NEO.centerModals = ($element) ->
    $modals = undefined
    if $element.length
      $modals = $element
    else
      $modals = $('.modal-vcenter:visible')
    $modals.each (i) ->
      $clone = $(this).clone().css('display', 'block').appendTo('body')
      top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2)
      top = if top > 0 then top else 0
      $clone.remove()
      $(this).find('.modal-content').css 'margin-top', top

  #2-1 モーダル表示時にモーダル中央表示にする
  $('.modal-vcenter').on 'show.bs.modal', (e) ->
    window.NEO.centerModals $(this)

  #2.2 ウィンドウのリサイズを検知しモーダルを再配置
  $(window).on 'resize', window.NEO.centerModals

  # 背画面のスクロールを固定し、モーダルを表示する
  window.NEO.show_modal_and_scroll_patch = (e)->
    $('#wrap').css({
      position: 'fixed',
      width: '100%',
      top: -1 * window.NEO.current_scrollY
    })
    $("#footer").hide()
    $("##{e.data.modalId}").modal('show', {
      large_image_src: $(this).attr("data-large-image-src"),
      product_id: $(this).attr("data-product-id")
    })

  #背画面のスクロール固定を解除し、モーダルを非表示にする
  window.NEO.hide_modal_and_scroll_patch = (e)->
    $('#wrap').attr({style: ''})
    b = $("#footer");
    $("#wrap").css("margin-bottom", (-b.outerHeight()) + "px");
    $("#wrap").css("padding-bottom", (b.outerHeight()) + "px");
    $("#footer").show()
    $('html, body').prop({scrollTop: window.NEO.current_scrollY})

  # ================================================
  # 共通初期化
  # ================================================
  # allow_submitクラスを付けないかぎり、formのエンターキーでの送信を抑制
  $(document).on "keypress", "input:not(.allow_submit)", (event) -> event.which != 13

  #フッターを画面最下部に補正して設置
  b = $("#footer");
  $("#wrap").css("margin-bottom",(-b.outerHeight())+"px");
  $("#wrap").css("padding-bottom",(b.outerHeight())+"px");

  # iPhone及びAndroidの際に対象クラスがある場合電話をかけるリンクとする
  ua = navigator.userAgent
  if ua.indexOf('iPhone') > 0 or ua.indexOf('Android') > 0
    $('.tel-link').each ->
      if $(this).is('img')
        str = $(this).attr('alt')
        $(this).wrap '<a href="tel:' + str.replace(/-/g, '') + '"></a>'
      else
        str = $(this).text()
        $(this).replaceWith '<a href="tel:' + str.replace(/-/g, '') + '">' + str + '</a>'

  #ヘッダーのお問い合わせモーダルを共通適用
  $(".contact_us_modal_btn").click({modalId : "contact_us_modal"},window.NEO.show_modal_and_scroll_patch)
  $('#contact_us_modal').on('hide.bs.modal', window.NEO.hide_modal_and_scroll_patch)

  #common-1 セコムボタンを押したときに
  $('#secomwindowopen').on 'click', (e) ->
    e.preventDefault();
    $('#CertificationPageForm').remove();
    $('<form/>',{id:"CertificationPageForm",method:'post',action:'https://www.login.secomtrust.net/customer/customer/pfw/CertificationPage.do',target:'_blank'})
    .append($('<input/>',{type:'hidden',name:'Req_ID',value:'8207004205'}))
    .appendTo document.body
    .submit();
  #common-1 セコムボタンを押したときに
  $('#secomwindowopen').on 'touchstart', (e) ->
    e.preventDefault();
    $('#CertificationPageForm').remove();
    $('<form/>',{id:"CertificationPageForm",method:'post',action:'https://www.login.secomtrust.net/customer/customer/pfw/CertificationPage.do',target:'_blank'})
    .append($('<input/>',{type:'hidden',name:'Req_ID',value:'8207004205'}))
    .appendTo document.body
    .submit();

  #common-2 個人情報保護リンク
  $('.privacy_daicolo_link').on 'click', (e) ->
    e.preventDefault();
    window.open(window.NEO.url.page_help_privacy, '_blank')
  $('.privacy_daicolo_link').on 'touchstart', (e) ->
    e.preventDefault();
    window.open(window.NEO.url.page_help_privacy, '_blank')

  $('map').imageMapResize();
